import { Injectable } from '@angular/core';
import * as MQTT from 'mqtt';
import { MqttClient } from "mqtt/dist/mqtt"; //new
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MqttService {
  private sendTime = 0;
  private receiveTime = 0;
  public timeDifference = 0;
  client: MqttClient;
  mensaje = new BehaviorSubject({});

  subastaTopic: any = "";
  auctionConfigTopic: any = "";

  /**
   * En función del tipo de pantalla se redirige o no entre las distintas secciones.
   * @tipoPantalla 1 splash - panel subasta
   * @tipoPantalla 2 arribos/ventas - panel subasta
   * @tipoPantalla 3 arribos - panel subasta
   * @tipoPantalla 4 ventas - panel subasta
   * @tipoPantalla 5 arribos
   * @tipoPantalla 6 ventas
   * @tipoPantalla 7 arribos - ventas
   */
  tipoPantalla = null;

  constructor(
    private router: Router,
  ) { }

  conexion() {
    if (this.client) { return; }
    this.client = MQTT.connect({
      username: environment.MQTT_USER,
      password: environment.MQTT_PASSWD,
      hostname: environment.MQTT_SERVER_URL,
      port: environment.MQTT_PORT,
      protocol: environment.MQTT_PROTOCOL == "ws" ? "ws" : "wss",
      keepalive: 20,
      // protocolVersion: 5,
      will: { retain: false, topic: 'pantallaAlive', payload: '{"event":"dc","codS":"dc"}', qos: 1 }
    });
    this.addEventListeners();
  }

  private addEventListeners(): void {
    this.client.addListener('offline', this.offline);
    this.client.addListener('connect', this.onConnect);
    this.client.addListener('message', this.onMessage);
    this.client.addListener('error', this.onLog);
    this.client.addListener('packetsend', this.onPacketSend);
    this.client.addListener('packetreceive', this.onPacketReceive);
  }

  private onPacketSend = (a) => {
    if (a.cmd == 'pingreq') {
      this.sendTime = new Date().getTime();
    }
  };

  // Add a new method to handle the packetreceive event
  private onPacketReceive = (a) => {
    if (a.cmd == 'pingresp') {
      this.receiveTime = new Date().getTime();
      this.timeDifference = this.receiveTime - this.sendTime;
    }
  };

  offline() {
    console.log("listener cliente mqtt conexion offline");
  }

  private onConnect: () => any = () => {
    // this.client.subscribe("monitor");
    this.client.subscribe("alive/#");
  };

  //Listener de mensajes entrantes
  private onMessage = (topicUrl: string, message: Uint8Array) => {
    if (message.toString() != '') {
      console.log(message.toString());
      let data = JSON.parse(message.toString());
      this.mensaje.next(data);
      switch (data['event']) {
        case 'suba': {
          if (!data['cod'] || data['cod'] == "undefined" || data['cod'] == "null") { return; } // Si viene esto vacio mal asunto
          this.subastaTopic = "subasta/" + data['cod'];
          this.auctionConfigTopic = "AuctionConfig/" + data["cod"];
          // this.auctionConfigTopic = "AuctionConfig";
          this.suscripcion(this.subastaTopic);
          this.suscripcion(this.auctionConfigTopic);
          this.router.navigate(["/panel"], { replaceUrl: true });
          break;
        }
        case "reload-pantalla": {
          window.location.reload();
          break;
        }
      }
    }
  }

  private onLog = (error) => {
    console.log(error);
  }

  //SUSCRIPCION
  public suscripcion(text) {
    this.client.subscribe(text);
  }

  //DESUSCRIBIR
  public desuscribir(text) {
    this.client.unsubscribe(text);
  }

  /**
   * Limpia los topics de subasta y auctionconfig a los que se está suscrito para cuando se finaliza la subasta.
   */
  endSubasta() {
    try {
      this.desuscribir(this.subastaTopic);
      this.desuscribir(this.auctionConfigTopic);
    } catch (error) {
      console.log(this.subastaTopic);
      console.log(this.auctionConfigTopic);
      console.log("Error de desuscripcion de topics de subasta y auctionconfig ", error);
    }
    // this.subastaTopic = "";
    // this.auctionConfigTopic = "";
  }

  monitorTopiSubscribe(code?) {
    let topic = code ? "monitor/" + code : "monitor";
    console.log("Suscripcion a topic ", topic);
    this.suscripcion(topic);
  }

}
