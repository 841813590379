import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /**
   * Código de sesión. El código se recibe por parámetro url. Se corresponde con la vendeduría. De no haber código las llamadas serán para todos.
   */
  code: any = null;

  constructor(
    private http: HttpClient,
  ) { }

  public getToken() {
    let url = environment.APIURL + '/authorize'
    let header = new HttpHeaders({ 'Authorization': 'hVs7TcMYtsZjbNU4xGVhu0q3OIWza75C625BvoL9jkE' })
    return this.http.post<any>(url, {}, { headers: header }).pipe(
      mergeMap((response: any) => {
        return of(response);
      })
    )
  }

  /**
   * Llamada al conjunto de fotos de la partida. A la llamada se le debe enviar un JSON con el formato {"idPart":nºpartida}
   */
  public getFoto(lista) {
    let url = environment.APIURL + "/getFoto"
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let header = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'] }).append('content-type', 'application/json');
        return this.http.post<any>(url, { "idPart": lista }, { headers: header });
      }),
      tap((data: any) => {
        return data;
      }));
  }

  /**
   * Llamada para recibir todos los parametros de configuracion de subasta. Es necesario filtrar el resultado para obtener la configuracion de la pantalla.
   * @returns 
   */
  public getConfig() {
    let url = environment.APIURL + '/configSubasta';
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'], 'content-type': 'application/json ' });
        let httpOptions = { headers: headers };
        return this.http.get<any>(url, httpOptions);
      }),
      tap((data: any) => {
        return data;
      }));
  }

  public getListadoArribos() {
    let cod = this.code ? "/" + this.code : ""
    let url = environment.APIURL + "/listadoPartidas" + cod;
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let header = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'] }).append('content-type', 'application/json');
        return this.http.get<any>(url, { headers: header });
      }),
      tap((data: any) => {
        return data;
      }));
  }
  public getListadoVentas() {
    let cod = this.code ? "/" + this.code : ""
    let url = environment.APIURL + '/getAdj' + cod;;
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let header = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'] }).append('content-type', 'application/json');
        return this.http.get<any>(url, { headers: header });
      }),
      tap((data: any) => {
        return data;
      }));
  }
}
