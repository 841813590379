// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environment_data } from "src/assets/logos/environment.data";
export const environment = {
  production: false,
  APIURL: environment_data.APIURL,
  // APIURL: 'http://192.168.33.180:5001', // APUNTANDO A PC ANDRES
  // APIURL: "http://192.168.33.48:5001", // PC PABLO
  // APIURL: 'https://subasta-login.saecdata.net', // APUNTANDO A DEVTEST DEMO
  // APIURL: 'https://stg-api-subasta.docpeixe.es', // APUNTANDO A DEVTEST ACERGA
  // APIURL: 'https://apisubasta-apvigo.saecdata.net', // APUNTANDO A APVIGO DEVTEST
  // APIURL: "http://192.168.10.87:5001", // 87
  // APIURL: 'https://subasta-api.apvigo.es', // APUNTANDO A APVIGO

  MQTT_SERVER_URL:environment_data.MQTT_SERVER_URL,
  // MQTT_SERVER_URL: 'mqtt.saecdata.net', // DEVTEST
  // MQTT_SERVER_URL: 'subasta-mqtt.apvigo.es', // APUNTANDO A APVIGO PROD
  // MQTT_SERVER_URL: "192.168.10.87", // 87

  MQTT_PROTOCOL: environment_data.MQTT_PROTOCOL,
  // MQTT_PROTOCOL: "wss",
  // MQTT_PROTOCOL: "ws",

  MQTT_USER:environment_data.MQTT_USER,
  // MQTT_USER: 'reloj', // MQTT USER PARA 87
  // MQTT USER PARA DEVTEST VIGO▼
  // MQTT_USER: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGl0eSI6IjQ1NTYiLCJzdWIiOiI0NTU2IiwiZXhwIjoyNTM0MDIzMDA3OTksInR5cGUiOiJhY2Nlc3MifQ.CnoXVkSpwTpnHjYzyRijahB6VpFk-996IXtoCj9o7U0',
  // MQTT USER STG ACERGA
  // MQTT_USER: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGl0eSI6IjQ1NDAiLCJzdWIiOiI0NTQwIiwiZXhwIjoyNTM0MDIzMDA3OTksInR5cGUiOiJhY2Nlc3MifQ.Ixv37yUTfyIzUNmGU1sxHNAIq88uhhAm1Ieoe_GwRP8',
  
  MQTT_PASSWD: environment_data.MQTT_PASSWD,
  // MQTT_PASSWD: '12345678',
  
  MQTT_PORT: environment_data.MQTT_PORT,
  // MQTT_PORT: 8083,
  // MQTT_PORT: 8085, //docker apvigo devtest

  DEFAULT_TIPO_PANTALLA: "2", //tipo de pantalla por defecto // Valores esperados del 1 al 7 //1 ACERGA - 2 VIGO
  VERSION: 1.0,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
